import React, { useState, useEffect } from 'react'
import { EXPORT_FORMATS, EXPORT_QUALITY_PRESETS } from '../../PrintConstants'
import scss from './ExportOptions.scss'

const ExportOptions = ({ onExport, onClose, visibleLayers = [] }) => {
  const [selectedFormat, setSelectedFormat] = useState(EXPORT_FORMATS.PDF.id)
  const [selectedQuality, setSelectedQuality] = useState(
    EXPORT_QUALITY_PRESETS.HIGH.id
  )
  const [selectedLayers, setSelectedLayers] = useState([])

  // Initialize selected layers with all visible layers
  useEffect(() => {
    setSelectedLayers(visibleLayers.map(layer => layer.id))
  }, [visibleLayers])

  const handleExport = () => {
    onExport({
      format: selectedFormat,
      quality: selectedQuality,
      layers: selectedLayers,
    })
    onClose()
  }

  const handleLayerToggle = layerId => {
    setSelectedLayers(prev => {
      if (prev.includes(layerId)) {
        return prev.filter(id => id !== layerId)
      }
      return [...prev, layerId]
    })
  }

  const handleSelectAll = () => {
    setSelectedLayers(visibleLayers.map(layer => layer.id))
  }

  const handleSelectNone = () => {
    setSelectedLayers([])
  }

  return (
    <div className={scss.exportOptionsModal}>
      <div className={scss.exportOptionsContent}>
        <h3>Export Options</h3>

        <div className={scss.optionSection}>
          <label>Format</label>
          <select
            value={selectedFormat}
            onChange={e => setSelectedFormat(e.target.value)}
          >
            {Object.values(EXPORT_FORMATS).map(format => (
              <option key={format.id} value={format.id}>
                {format.label}
              </option>
            ))}
          </select>
        </div>

        <div className={scss.optionSection}>
          <label>Quality</label>
          <select
            value={selectedQuality}
            onChange={e => setSelectedQuality(e.target.value)}
          >
            {Object.values(EXPORT_QUALITY_PRESETS).map(quality => (
              <option key={quality.id} value={quality.id}>
                {quality.label}
              </option>
            ))}
          </select>
        </div>

        <div className={scss.optionSection}>
          <div className={scss.layersHeader}>
            <label>Layers</label>
            <div className={scss.layerControls}>
              <button type='button' onClick={handleSelectAll}>
                Select All
              </button>
              <button type='button' onClick={handleSelectNone}>
                Select None
              </button>
            </div>
          </div>
          <div className={scss.layersList}>
            {visibleLayers.map(layer => (
              <div key={layer.id} className={scss.layerItem}>
                <label>
                  <input
                    type='checkbox'
                    checked={selectedLayers.includes(layer.id)}
                    onChange={() => handleLayerToggle(layer.id)}
                  />
                  {layer.layer?.name || layer.name}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className={scss.buttonGroup}>
          <button type='button' onClick={onClose}>
            Cancel
          </button>
          <button type='button' onClick={handleExport}>
            Export
          </button>
        </div>
      </div>
    </div>
  )
}

export default ExportOptions
