// External Dependencies
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { size } from 'lodash'
import MapGL from 'react-map-gl'
import { Rnd } from 'react-rnd'
import { Font } from '@react-pdf/renderer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUndo,
  faRedo,
  faChevronLeft,
  faChevronDown,
  faCog,
  faSpinner,
  faArrowsAlt,
  faDownload,
  faPlus,
  faMinus,
  faExpand,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faTimes,
  faArrowUp,
  faGripLines,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons'
import html2canvas from 'html2canvas'

// Actions
import { setToolConfig, setToc, setMapMode } from '../../../actions/index'

// Components
import Viewer from './Viewer'
import ImageElement from './LayoutElements/ImageElement/ImageElement'
import TextElement from './LayoutElements/TextElement/TextElement'
import DateElement from './LayoutElements/DateElement/DateElement'
import ElementButtons from './LayoutElements/ElementButtons/ElementButtons'
import LegendElement from './LayoutElements/LegendElement/LegendElement'
import NorthArrowElement from './LayoutElements/NorthArrowElement/NorthArrowElement'
import Scale from './LayoutElements/ScaleElement/ScaleElement'
import { ElementStyle } from './ElementStyle/ElementStyle'
import PrintLayouts from './PrintLayouts'
import PrintSettings from './Modals/PrintSettings/PrintSettings'
import ExportOptions from './Modals/ExportOptions/ExportOptions'

// Utilities
import { getVisibleLayersFromDataConfig } from '../../../utilities/dataConfig'
import { clone } from '../../../utilities/geospatial'

// Styles
import scss from './Print.scss'
import dropdownScss from '../../CSSModules/Dropdown.scss'

// Constants
import {
  PAGE_SIZE_LOOKUP,
  MAP_ELEMENT,
  TEXT_ELEMENT,
  NORTH_ARROW,
  SCALE_BAR,
  LEGEND_ELEMENT,
  DEFAULT_SELECT_OPTION,
  EXPORT_FORMATS,
  EXPORT_QUALITY_PRESETS,
} from './PrintConstants'

// Icons
import textIcon from '../../../assets/icons/print/text.svg'
import plusIcon from '../../../assets/icons/accordian.svg'
import dateIcon from '../../../assets/icons/print/dateStamp.svg'
import northIcon from '../../../assets/icons/orientNorth.svg'
import legendIcon from '../../../assets/icons/print/legend.svg'
import imageIcon from '../../../assets/icons/print/image.svg'
import lockIcon from '../../../assets/icons/print/Lock.svg'
import unlockIcon from '../../../assets/icons/print/Unlock.svg'
import exportIcon from '../../../assets/icons/print/Export.svg'
import printIcon from '../../../assets/icons/print/Print.svg'

// Fonts
import GilroyRegular from '../../../assets/fonts/Gilroy-Light.ttf'
import GilroyBold from '../../../assets/fonts/Gilroy-Medium.ttf'
import GilroyBoldItalic from '../../../assets/fonts/Gilroy-MediumItalic.ttf'
import MontserratRegular from '../../../assets/fonts/Montserrat-Light.ttf'
import MontserratBold from '../../../assets/fonts/Montserrat-Medium.ttf'
import MontserratBoldItalic from '../../../assets/fonts/Montserrat-MediumItalic.ttf'
import OpenSansRegular from '../../../assets/fonts/OpenSans-Light.ttf'
import OpenSansBold from '../../../assets/fonts/OpenSans-SemiBold.ttf'
import OpenSansBoldItalic from '../../../assets/fonts/OpenSans-SemiBoldItalic.ttf'

// Add icons to library
library.add(
  faUndo,
  faRedo,
  faChevronLeft,
  faChevronDown,
  faCog,
  faSpinner,
  faArrowsAlt,
  faDownload,
  faPlus,
  faMinus,
  faExpand,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faTimes,
  faArrowUp,
  faGripLines,
  faArrowDown
)

const MOBILE_BREAKPOINT = 768
const PrintFC = ({
  viewport,
  mapStyle,
  dataConfig,
  onViewportChange,
  onMapLoad,
  onMapClickNative,
  transformRequest,
  onTransitionEnd,
  interactiveLayerIds,
  getCursor,
}) => {
  // State management with hooks
  const [mode, setMode] = useState('LAYOUT')
  const [orientPortrait, setOrientPortrait] = useState(false)
  const [elements, setElements] = useState([])
  const [history, setHistory] = useState({ past: [], present: [], future: [] })
  const [dpi, setDpi] = useState(240)
  const [editMode, setEditMode] = useState(false)
  const [activeElement, setActiveElement] = useState(null)
  const [activeElementWindow, setActiveElementWindow] = useState(null)
  const [textAreaElements, setTextAreaElements] = useState(false)
  const [dateStampElements, setDateStampElements] = useState(false)
  const [legendElementVisible, setLegendElementVisible] = useState(false)
  const [northArrowElements, setNorthArrowElements] = useState(false)
  const [scaleBarElement, setScaleBarElement] = useState(true)
  const [layoutName, setLayoutName] = useState('')
  const [layoutNameError, setLayoutNameError] = useState('')
  const [selectedLayout, setSelectedLayout] = useState(DEFAULT_SELECT_OPTION)
  const [savedLayouts, setSavedLayouts] = useState([])
  const [layoutOption, setLayoutOption] = useState([])
  const [editToolPosition, setEditToolPosition] = useState({ x: 0, y: 0 })
  const [lockMap, setLockMap] = useState(false)
  const [excludedLayers, setExcludedLayers] = useState([])
  const [pageSize, setPageSize] = useState('LETTER')
  const [containerWidth, setContainerWidth] = useState(window.innerWidth - 363)
  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight - 45
  )
  const [showInsertDropdown, setShowInsertDropdown] = useState(false)
  const [showFileDropdown, setShowFileDropdown] = useState(false)
  const [printSettingsVisible, setPrintSettingsVisible] = useState(false)
  const [viewportSet, setViewportSet] = useState(false)
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= MOBILE_BREAKPOINT
  )
  const [showExportOptions, setShowExportOptions] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(100)
  const minZoom = 25
  const maxZoom = 200
  const zoomStep = 25

  // Refs
  const inputRef = useRef()
  const mapRef = useRef()
  const imgRef = useRef()

  // Redux hooks
  const dispatch = useDispatch()
  const toolConfig = useSelector(state => state.toolConfig)
  const user = useSelector(state => state.user)

  // Destructure scss classes for handles
  const {
    rndTopLeftCornerHandle,
    rndTopRightCornerHandle,
    rndBottomRightCornerHandle,
    rndBottomLeftCornerHandle,
    rndTopHandle,
    rndRightHandle,
    rndBottomHandle,
    rndLeftHandle,
  } = scss

  // Helper functions
  const updateDimensions = useCallback(() => {
    const isMobileView = window.innerWidth <= MOBILE_BREAKPOINT
    setIsMobile(isMobileView)

    // Adjust dimensions based on screen size
    const updatedWidth = isMobileView
      ? window.innerWidth - 20 // 10px padding on each side
      : window.innerWidth - 363
    const updatedHeight = isMobileView
      ? window.innerHeight - 200 // Additional space for stacked controls
      : window.innerHeight - 45

    setContainerWidth(updatedWidth)
    setContainerHeight(updatedHeight)
  }, [])

  const setInitialMap = useCallback(async () => {
    const style = mapStyle.toJS()
    const { tocLayers } = dataConfig
    const visibleLayers = getVisibleLayersFromDataConfig(tocLayers, style)
    let initPrintElements = []

    if (visibleLayers.length) {
      initPrintElements = [
        MAP_ELEMENT,
        TEXT_ELEMENT,
        NORTH_ARROW,
        SCALE_BAR,
        LEGEND_ELEMENT,
      ]
    } else {
      initPrintElements = [MAP_ELEMENT, TEXT_ELEMENT, NORTH_ARROW, SCALE_BAR]
    }

    initPrintElements = initPrintElements.map((ele, index) => ({
      ...ele,
      id: index + 1,
    }))

    setMode('LAYOUT')
    setOrientPortrait(false)
    setElements(initPrintElements)
    setHistory({
      past: [],
      present: initPrintElements,
      future: [],
    })
  }, [mapStyle, dataConfig])

  // Effect hooks
  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    setInitialMap()

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [updateDimensions, setInitialMap])

  useEffect(() => {
    // Calculate appropriate zoom level when layout size changes
    const height = orientPortrait
      ? PAGE_SIZE_LOOKUP[pageSize].height
      : PAGE_SIZE_LOOKUP[pageSize].width
    const width = orientPortrait
      ? PAGE_SIZE_LOOKUP[pageSize].width
      : PAGE_SIZE_LOOKUP[pageSize].height

    // Add 10% padding around the layout
    const paddingFactor = 0.9 // This gives us 5% padding on each side

    // Calculate zoom levels that would fit width and height with padding
    const widthZoom = ((containerWidth * paddingFactor) / width) * 100
    const heightZoom = (((containerHeight - 74) * paddingFactor) / height) * 100 // Subtract toolbar height

    // Use the smaller zoom level to ensure entire layout is visible
    const fitZoom = Math.min(widthZoom, heightZoom)

    // Round to nearest step and clamp between min and max zoom
    const roundedZoom = Math.round(fitZoom / zoomStep) * zoomStep
    const newZoom = Math.max(minZoom, Math.min(roundedZoom, maxZoom))

    setZoomLevel(newZoom)
  }, [
    pageSize,
    orientPortrait,
    containerWidth,
    containerHeight,
    minZoom,
    maxZoom,
    zoomStep,
  ])

  // Event Handlers and Utility Functions
  const showPrintSettings = useCallback(visible => {
    setPrintSettingsVisible(visible)
  }, [])

  // Add this function to calculate the appropriate zoom level
  const calculateFitZoomLevel = useCallback(() => {
    const height = orientPortrait
      ? PAGE_SIZE_LOOKUP[pageSize].height
      : PAGE_SIZE_LOOKUP[pageSize].width
    const width = orientPortrait
      ? PAGE_SIZE_LOOKUP[pageSize].width
      : PAGE_SIZE_LOOKUP[pageSize].height

    // Add 10% padding around the layout
    const paddingFactor = 0.9 // This gives us 5% padding on each side

    // Calculate zoom levels that would fit width and height with padding
    const widthZoom = ((containerWidth * paddingFactor) / width) * 100
    const heightZoom = (((containerHeight - 74) * paddingFactor) / height) * 100 // Subtract toolbar height

    // Use the smaller zoom level to ensure entire layout is visible
    const fitZoom = Math.min(widthZoom, heightZoom)

    // Round to nearest step and clamp between min and max zoom
    const roundedZoom = Math.round(fitZoom / zoomStep) * zoomStep
    return Math.max(minZoom, Math.min(roundedZoom, maxZoom))
  }, [
    containerWidth,
    containerHeight,
    orientPortrait,
    pageSize,
    zoomStep,
    minZoom,
    maxZoom,
  ])

  // Update toggleOrient to include zoom adjustment
  const toggleOrient = useCallback(() => {
    const newOrientPortrait = !orientPortrait
    setOrientPortrait(newOrientPortrait)

    const height = newOrientPortrait
      ? PAGE_SIZE_LOOKUP[pageSize].height
      : PAGE_SIZE_LOOKUP[pageSize].width
    const width = newOrientPortrait
      ? PAGE_SIZE_LOOKUP[pageSize].width
      : PAGE_SIZE_LOOKUP[pageSize].height

    setElements(prevElements =>
      prevElements.map(elem => ({
        ...elem,
        width: elem.width > width ? width - 10 : elem.width,
        height: elem.height > height ? height - 10 : elem.height,
      }))
    )
  }, [orientPortrait, pageSize])

  const toggleMapLock = useCallback(() => {
    setLockMap(prev => !prev)
  }, [])

  const handleSaveToImg = useCallback(() => {
    if (!mapRef.current) return
    const mapboxobj = mapRef.current.getMap()
    const mapCanvas = mapboxobj.getCanvas()
    const mapImage = mapCanvas.toDataURL('image/png')
    const link = document.createElement('a')
    link.download = 'image.png'
    link.href = mapImage
    link.click()
  }, [])

  // Add new handlers for history management
  const pushToHistory = useCallback(newElements => {
    setHistory(prev => ({
      past: [...prev.past, prev.present],
      present: newElements,
      future: [],
    }))
  }, [])

  const handleDeleteElement = useCallback(
    id => {
      setElements(prevElements => {
        const newElements = prevElements.filter(elem => elem.id !== id)
        pushToHistory(newElements)
        return newElements
      })
      setActiveElement(null)
    },
    [pushToHistory]
  )

  const handleUpdateElements = useCallback(
    newElements => {
      setElements(newElements)
      pushToHistory(newElements)
    },
    [pushToHistory]
  )

  const getVisibleLayers = useCallback(() => {
    const style = mapStyle.toJS()
    const { tocLayers } = dataConfig
    const legendLayers = getVisibleLayersFromDataConfig(tocLayers, style)
    const uniqueLegendLayers = Array.from(
      new Set(legendLayers.map(JSON.stringify)),
      JSON.parse
    )

    return uniqueLegendLayers
  }, [mapStyle, dataConfig])

  const handleAddLegend = useCallback(() => {
    if (elements.find(obj => obj.type === 'legend')) return

    const neededLayers = getVisibleLayers()
    if (size(neededLayers) > 0) {
      const newLegend = clone(LEGEND_ELEMENT)
      newLegend.id = elements.length ? elements[elements.length - 1].id + 1 : 1
      newLegend.layers = neededLayers
      setElements(prev => [...prev, newLegend])
    }
  }, [elements, getVisibleLayers])

  const handleDeleteItemLegend = useCallback(id => {
    setElements(prevElements => {
      const newElements = [...prevElements]
      const legendElement = newElements.find(obj => obj.type === 'legend')
      if (legendElement) {
        const neededLegendItemIndex = legendElement.layers.findIndex(
          obj => obj.layer.id === id
        )
        if (neededLegendItemIndex !== -1) {
          legendElement.layers.splice(neededLegendItemIndex, 1)
        }
      }
      return newElements
    })
  }, [])

  const handleRefreshItemLegend = useCallback(() => {
    setExcludedLayers([])
  }, [])

  const handleImageChange = useCallback(
    e => {
      e.preventDefault()
      const file = e.target.files[0]
      if (!file) return

      const reader = new FileReader()
      e.target.value = ''

      reader.onloadend = () => {
        const newImage = {
          file,
          id: elements.length ? elements[elements.length - 1].id + 1 : 1,
          type: 'img',
          imagePreviewUrl: reader.result,
          x: 0,
          y: 0,
          width: 150,
          height: 150,
          borderWidth: 1,
          borderColor: '#000000',
        }
        setElements(prev => [...prev, newImage])
      }
      reader.readAsDataURL(file)
    },
    [elements]
  )

  const getDate = useCallback(() => {
    const d = new Date()
    const month = String(d.getMonth() + 1).padStart(2, '0')
    const day = String(d.getDate()).padStart(2, '0')
    const year = d.getFullYear()
    return [year, month, day].join('-')
  }, [])

  const handleAddText = useCallback(
    positionY => {
      const newInput = clone(TEXT_ELEMENT)
      newInput.id = elements.length ? elements[elements.length - 1].id + 1 : 1
      newInput.y = positionY
      const newElements = [...elements, newInput]
      setElements(newElements)
      pushToHistory(newElements)
    },
    [elements, pushToHistory]
  )

  const handleAddDateStamp = useCallback(() => {
    const newInput = {
      id: elements.length ? elements[elements.length - 1].id + 1 : 1,
      type: 'date',
      text: getDate(),
      x: 200,
      y: 420,
      width: 90.70933333333333,
      height: 28.346666666666668,
      borderWidth: 1,
      borderColor: '#000000',
      borderTransparent: false,
      textColor: '#000000',
      fontSize: 12,
      fontFamily: 'Montserrat',
      bgColor: '#ffffff',
      bgTransparent: false,
      opacity: 100,
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'none',
      textAlign: 'left',
      padding: 5,
    }
    const newElements = [...elements, newInput]
    setElements(newElements)
    pushToHistory(newElements)
  }, [elements, getDate, pushToHistory])

  const handleChangeText = useCallback(
    event => {
      const { id, value } = event.target
      setElements(prevElements => {
        const newElements = [...prevElements]
        const neededInput = newElements.find(obj => obj.id === Number(id))
        if (neededInput) {
          neededInput.text = value
        }
        pushToHistory(newElements)
        return newElements
      })
    },
    [pushToHistory]
  )

  const handleChangeScale = useCallback(
    (distance, ratio, units, maxWidth) => {
      setElements(prevElements => {
        const newElements = [...prevElements]
        const scaleElement = newElements.find(obj => obj.type === 'scaleBar')
        if (scaleElement) {
          scaleElement.distance = `${distance} ${units}`
          scaleElement.ratio = ratio
          scaleElement.maxWidth = maxWidth
        }
        pushToHistory(newElements)
        return newElements
      })
    },
    [pushToHistory]
  )

  const handleTextBlur = useCallback(event => {
    if (!event.target.value) {
      const id = Number(event.target.id)
      setElements(prevElements => {
        const newElements = [...prevElements]
        const neededInputIndex = newElements.findIndex(obj => obj.id === id)
        if (neededInputIndex !== -1) {
          newElements.splice(neededInputIndex, 1)
        }
        return newElements
      })
    }
  }, [])

  const copyText = useCallback(id => {
    setElements(prevElements => {
      const newElements = [...prevElements]
      const neededTextIndex = newElements.findIndex(obj => obj.id === id)
      if (neededTextIndex !== -1) {
        const newText = {
          ...newElements[neededTextIndex],
          id: newElements.length
            ? newElements[newElements.length - 1].id + 1
            : 1,
          x: newElements[neededTextIndex].x + 5,
          y: newElements[neededTextIndex].y + 5,
        }
        return [...newElements, newText]
      }
      return newElements
    })
  }, [])

  const handleAddNorthArrow = useCallback(() => {
    if (elements.find(obj => obj.type === 'northArrow')) return

    const newNorthArrow = clone(NORTH_ARROW)
    newNorthArrow.id = elements.length
      ? elements[elements.length - 1].id + 1
      : 1
    setElements(prev => [...prev, newNorthArrow])
  }, [elements])

  const handleAddScaleBar = useCallback(() => {
    if (elements.find(obj => obj.type === 'scaleBar')) return

    const newScaleBar = clone(SCALE_BAR)
    newScaleBar.id = elements.length ? elements[elements.length - 1].id + 1 : 1
    setElements(prev => [...prev, newScaleBar])
  }, [elements])

  const handleResizeStop = useCallback((e, direction, ref, delta, position) => {
    const width = parseInt(ref.style.width.replace('px', ''))
    const height = parseInt(ref.style.height.replace('px', ''))

    setElements(prevElements => {
      const newElements = [...prevElements]
      const neededObjIndex = newElements.findIndex(
        obj => obj.id === Number(ref.id)
      )
      if (neededObjIndex !== -1) {
        newElements[neededObjIndex] = {
          ...newElements[neededObjIndex],
          width,
          height,
          x: position.x,
          y: position.y,
        }
      }
      return newElements
    })
  }, [])

  const handleDragStop = useCallback((e, d) => {
    setElements(prevElements => {
      const newElements = [...prevElements]
      const neededObjIndex = newElements.findIndex(
        obj => obj.id === Number(d.node.id)
      )
      if (neededObjIndex !== -1) {
        newElements[neededObjIndex] = {
          ...newElements[neededObjIndex],
          x: d.lastX,
          y: d.lastY,
        }
      }
      return newElements
    })
  }, [])

  const updateEditToolPosition = useCallback(pos => {
    setEditToolPosition(pos)
  }, [])

  const toggleEditWindow = useCallback(
    id => {
      setEditMode(true)
      setActiveElement(id || null)
      if (!id) {
        updateEditToolPosition({ x: 0, y: 0 })
      }
    },
    [updateEditToolPosition]
  )

  const handleElementWindow = useCallback(
    object => {
      toggleEditWindow(object.id)
      setActiveElementWindow(object.id)
    },
    [toggleEditWindow]
  )

  const getActiveObject = useCallback(() => {
    if (!activeElement) return -1
    return elements.findIndex(obj => obj.id === activeElement)
  }, [activeElement, elements])

  const changeStyle = useCallback(
    object => {
      setElements(prevElements => {
        const newElements = [...prevElements]
        const activeIndex = getActiveObject()
        if (activeIndex !== -1) {
          newElements[activeIndex] = object
        }
        return newElements
      })
    },
    [getActiveObject]
  )

  const resetDropdowns = useCallback(() => {
    setShowFileDropdown(false)
    setShowInsertDropdown(false)
  }, [])

  const toggleInsertDropdown = useCallback(
    e => {
      e.stopPropagation()
      resetDropdowns()
      setShowInsertDropdown(prev => !prev)
    },
    [resetDropdowns]
  )

  const toggleFileDropdown = useCallback(
    e => {
      e.stopPropagation()
      resetDropdowns()
      setShowFileDropdown(prev => !prev)
    },
    [resetDropdowns]
  )

  const toggleMode = useCallback(() => {
    if (activeElement) {
      toggleEditWindow()
    }

    const newMode = mode === 'LAYOUT' ? 'PDF' : 'LAYOUT'
    if (newMode === 'PDF') {
      Font.register({
        family: 'Gilroy-Light',
        fonts: [
          { src: GilroyRegular },
          { src: GilroyBold, fontWeight: 'bold' },
          { src: GilroyBoldItalic, fontWeight: 'normal', fontStyle: 'italic' },
        ],
      })

      Font.register({
        family: 'Montserrat',
        fonts: [
          { src: MontserratRegular },
          { src: MontserratBold, fontWeight: 'bold' },
          {
            src: MontserratBoldItalic,
            fontWeight: 'normal',
            fontStyle: 'italic',
          },
        ],
      })

      Font.register({
        family: 'open sans',
        fonts: [
          { src: OpenSansRegular },
          { src: OpenSansBold, fontWeight: 'bold' },
          {
            src: OpenSansBoldItalic,
            fontWeight: 'normal',
            fontStyle: 'italic',
          },
        ],
      })

      updateDimensions()
    }
    setMode(newMode)
  }, [mode, activeElement, toggleEditWindow, updateDimensions])

  const getPDF = useCallback(() => {
    if (!mapRef.current?.getMap()) {
      toggleMode()
      return null
    }

    const mapboxobj = mapRef.current.getMap()
    const mapCanvas = mapboxobj.getCanvas()
    const mapImage = mapCanvas.toDataURL('image/png')

    return (
      <Viewer
        mapStyle={mapStyle}
        dataConfig={dataConfig}
        viewport={viewport}
        orientPortrait={orientPortrait}
        pageWidth={containerWidth}
        pageHeight={containerHeight}
        pageSize={pageSize}
        elements={elements}
        mapImage={mapImage}
        mapboxobj={mapboxobj}
        excludedLayers={excludedLayers}
      />
    )
  }, [
    mapStyle,
    dataConfig,
    viewport,
    orientPortrait,
    containerWidth,
    containerHeight,
    pageSize,
    elements,
    excludedLayers,
    toggleMode,
  ])

  const getLayoutSize = useCallback(() => {
    const height = orientPortrait
      ? PAGE_SIZE_LOOKUP[pageSize].height
      : PAGE_SIZE_LOOKUP[pageSize].width
    const width = orientPortrait
      ? PAGE_SIZE_LOOKUP[pageSize].width
      : PAGE_SIZE_LOOKUP[pageSize].height

    // Account for zoom level
    const scaledWidth = width * (zoomLevel / 100)
    const scaledHeight = height * (zoomLevel / 100)

    return {
      layoutWidth: width, // Original width for alignment calculations
      layoutHeight: height, // Original height for alignment calculations
      scaledWidth, // Scaled width for display
      scaledHeight, // Scaled height for display
    }
  }, [orientPortrait, pageSize, zoomLevel])

  const handleExport = useCallback(
    async ({ format, quality, layers }) => {
      const qualitySettings = Object.values(EXPORT_QUALITY_PRESETS).find(
        q => q.id === quality
      )
      const formatSettings = Object.values(EXPORT_FORMATS).find(
        f => f.id === format
      )

      if (!mapRef.current?.getMap()) return

      const mapboxobj = mapRef.current.getMap()
      const mapCanvas = mapboxobj.getCanvas()

      // Update layer visibility based on selection
      const style = mapStyle.toJS()
      const { tocLayers } = dataConfig
      const allLayers = getVisibleLayersFromDataConfig(tocLayers, style)

      // Hide layers not selected for export
      allLayers.forEach(layer => {
        const isVisible = layers.includes(layer.id)
        if (mapboxobj.getLayer(layer.id)) {
          mapboxobj.setLayoutProperty(
            layer.id,
            'visibility',
            isVisible ? 'visible' : 'none'
          )
        }
      })

      // Handle different export formats
      if (format === EXPORT_FORMATS.PDF.id) {
        setDpi(qualitySettings.dpi)
        setExcludedLayers(
          allLayers
            .filter(layer => !layers.includes(layer.id))
            .map(layer => layer.id)
        )

        // Ensure all print elements are visible
        const printElements = elements.filter(elem => elem.type !== 'map')
        if (printElements.length > 0) {
          setElements(prevElements => {
            return prevElements.map(elem => ({
              ...elem,
              opacity: 100,
              visible: true,
            }))
          })
        }

        toggleMode() // Switch to PDF mode
      } else {
        // For image formats (PNG/JPEG)
        const layoutContainer = document.querySelector('.Layout')
        if (!layoutContainer) return

        try {
          // Temporarily hide element buttons during capture
          const elementButtons =
            layoutContainer.querySelectorAll('.ElementButtons')
          elementButtons.forEach(btn => {
            btn.style.display = 'none'
          })

          // Capture the entire layout
          const canvas = await html2canvas(layoutContainer, {
            useCORS: true,
            allowTaint: true,
            backgroundColor: '#ffffff',
            scale: qualitySettings.dpi / 96, // Convert DPI to scale factor
          })

          // Restore element buttons
          elementButtons.forEach(btn => {
            btn.style.display = ''
          })

          // Export the image
          const imageData = canvas.toDataURL(
            formatSettings.mimeType,
            qualitySettings.compression
          )

          // Restore original layer visibility
          allLayers.forEach(layer => {
            if (mapboxobj.getLayer(layer.id)) {
              mapboxobj.setLayoutProperty(layer.id, 'visibility', 'visible')
            }
          })

          const link = document.createElement('a')
          link.download = `MyAssetMap${formatSettings.extension}`
          link.href = imageData
          link.click()
        } catch (error) {
          console.error('Error during export:', error)
          // Restore original layer visibility in case of error
          allLayers.forEach(layer => {
            if (mapboxobj.getLayer(layer.id)) {
              mapboxobj.setLayoutProperty(layer.id, 'visibility', 'visible')
            }
          })
        }
      }
    },
    [
      mapRef,
      toggleMode,
      setDpi,
      mapStyle,
      dataConfig,
      setExcludedLayers,
      elements,
    ]
  )

  const pageClicked = useCallback(() => {
    resetDropdowns()
  }, [resetDropdowns])

  const handleViewportChange = useCallback(
    newViewport => {
      if (viewportSet) {
        onViewportChange(newViewport)
        setViewportSet(false)
      } else {
        setViewportSet(true)
      }
    },
    [viewportSet, onViewportChange]
  )

  const backToMap = useCallback(() => {
    dispatch(setToc(false))
    dispatch(setMapMode('MAP'))
  }, [dispatch])

  const handleUndo = useCallback(() => {
    setHistory(prev => {
      if (prev.past.length === 0) return prev
      const newPast = prev.past.slice(0, -1)
      const newPresent = prev.past[prev.past.length - 1]

      setElements(newPresent)

      return {
        past: newPast,
        present: newPresent,
        future: [prev.present, ...prev.future],
      }
    })
  }, [])

  const handleRedo = useCallback(() => {
    setHistory(prev => {
      if (prev.future.length === 0) return prev
      const newFuture = prev.future.slice(1)
      const newPresent = prev.future[0]

      setElements(newPresent)

      return {
        past: [...prev.past, prev.present],
        present: newPresent,
        future: newFuture,
      }
    })
  }, [])

  const handleZoomIn = useCallback(() => {
    setZoomLevel(prev => Math.min(prev + zoomStep, maxZoom))
  }, [])

  const handleZoomOut = useCallback(() => {
    setZoomLevel(prev => Math.max(prev - zoomStep, minZoom))
  }, [])

  const handleResetZoom = useCallback(() => {
    setZoomLevel(100)
  }, [])

  const handleAlign = useCallback(
    alignment => {
      const { layoutWidth } = getLayoutSize()
      setElements(prevElements => {
        const newElements = [...prevElements]
        const activeIndex = activeElement ? getActiveObject() : -1

        // If no element is selected, align all elements
        if (activeIndex === -1) {
          newElements.forEach(element => {
            // Store original y position
            const originalY = element.y

            switch (alignment) {
              case 'left':
                element.x = 0
                break
              case 'center':
                element.x = Math.max(
                  0,
                  Math.min(
                    (layoutWidth - element.width) / 2,
                    layoutWidth - element.width
                  )
                )
                break
              case 'right':
                element.x = Math.max(0, layoutWidth - element.width)
                break
              default:
                break
            }
            // Restore original y position
            element.y = originalY
          })
        } else {
          // Align just the selected element
          const element = newElements[activeIndex]
          const originalY = element.y

          switch (alignment) {
            case 'left':
              element.x = 0
              break
            case 'center':
              element.x = Math.max(
                0,
                Math.min(
                  (layoutWidth - element.width) / 2,
                  layoutWidth - element.width
                )
              )
              break
            case 'right':
              element.x = Math.max(0, layoutWidth - element.width)
              break
            default:
              break
          }
          // Restore original y position
          element.y = originalY
        }
        return newElements
      })
    },
    [activeElement, getActiveObject, getLayoutSize]
  )

  const handleVerticalAlign = useCallback(
    alignment => {
      const { layoutHeight } = getLayoutSize()
      setElements(prevElements => {
        const newElements = [...prevElements]
        const activeIndex = activeElement ? getActiveObject() : -1

        // If no element is selected, align all elements
        if (activeIndex === -1) {
          newElements.forEach(element => {
            // Store original x position
            const originalX = element.x

            switch (alignment) {
              case 'top':
                element.y = 0
                break
              case 'middle':
                element.y = Math.max(
                  0,
                  Math.min(
                    (layoutHeight - element.height) / 2,
                    layoutHeight - element.height
                  )
                )
                break
              case 'bottom':
                element.y = Math.max(0, layoutHeight - element.height)
                break
              default:
                break
            }
            // Restore original x position
            element.x = originalX
          })
        } else {
          // Align just the selected element
          const element = newElements[activeIndex]
          const originalX = element.x

          switch (alignment) {
            case 'top':
              element.y = 0
              break
            case 'middle':
              element.y = Math.max(
                0,
                Math.min(
                  (layoutHeight - element.height) / 2,
                  layoutHeight - element.height
                )
              )
              break
            case 'bottom':
              element.y = Math.max(0, layoutHeight - element.height)
              break
            default:
              break
          }
          // Restore original x position
          element.x = originalX
        }
        return newElements
      })
    },
    [activeElement, getActiveObject, getLayoutSize]
  )

  // Update setPageSize to include zoom adjustment
  const handlePageSizeChange = useCallback(
    newPageSize => {
      setPageSize(newPageSize)
      setZoomLevel(calculateFitZoomLevel())
    },
    [calculateFitZoomLevel]
  )

  // Add useEffect to set initial zoom when component mounts
  useEffect(() => {
    setZoomLevel(calculateFitZoomLevel())
  }, [calculateFitZoomLevel])

  if (!viewport) return null

  const layoutSize = getLayoutSize()
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 1px #ddd',
    background: '#f0f0f0',
  }

  const layoutStyle = {
    width: isMobile ? '100%' : `${layoutSize.layoutWidth}px`,
    height: isMobile ? 'auto' : `${layoutSize.layoutHeight}px`,
    backgroundColor: '#fff',
    alignSelf: 'center',
    position: 'relative',
    margin: 'auto',
    marginTop: isMobile ? '8px' : '54px',
    padding: isMobile ? '8px' : '0',
    transform: `scale(${zoomLevel / 100})`,
    transformOrigin: 'top center',
    transition: 'transform 0.2s ease-out',
  }

  const hiddenInputStyle = {
    position: 'absolute',
    top: '-9999px',
  }

  const triggerInput = e => {
    e.persist()
    inputRef.current.click()
  }

  const dragPan = !lockMap
  const doubleClickZoom = !lockMap
  const scrollZoom = !lockMap

  let ui
  if (mode !== 'PDF') {
    ui = (
      <div className={scss.printMapLayout}>
        <div style={layoutStyle} className='Layout'>
          {!!elements.length &&
            elements.map(object => {
              const { type } = object
              if (type === 'map') {
                return (
                  <Rnd
                    key={object.id}
                    resizeHandleWrapperClass={scss.rndWrapperHandles}
                    resizeHandleClasses={{
                      topLeft: rndTopLeftCornerHandle,
                      topRight: rndTopRightCornerHandle,
                      bottomRight: rndBottomRightCornerHandle,
                      bottomLeft: rndBottomLeftCornerHandle,
                      top: rndTopHandle,
                      right: rndRightHandle,
                      bottom: rndBottomHandle,
                      left: rndLeftHandle,
                    }}
                    id={object.id}
                    className={[
                      scss.rndWrapperHover,
                      scss[
                        activeElementWindow === object.id
                          ? 'activeEditWindowMap'
                          : ''
                      ],
                    ].join(' ')}
                    onDragStop={handleDragStop}
                    onResize={handleResizeStop}
                    onClick={() => handleElementWindow(object)}
                    size={{
                      width: object.width,
                      height: object.height,
                    }}
                    maxWidth={`${layoutSize.layoutWidth}px`}
                    maxHeight={`${layoutSize.layoutHeight}px`}
                    dragGrid={[5, 5]}
                    resizeGrid={[5, 5]}
                    style={{
                      ...style,
                      borderColor: !object.borderTransparent
                        ? object.borderColor
                        : 'transparent',
                      borderWidth: `${object.borderWidth}px`,
                      borderStyle: 'solid',
                    }}
                    bounds='parent'
                    default={{
                      x: object.x,
                      y: object.y,
                      width: object.width,
                      height: object.height,
                    }}
                    position={{
                      x: object.x,
                      y: object.y,
                    }}
                    cancel='.map'
                  >
                    <div className='map'>
                      <MapGL
                        {...viewport}
                        width={object.width - object.borderWidth * 2}
                        height={object.height - object.borderWidth * 2}
                        mapStyle={mapStyle}
                        onViewportChange={handleViewportChange}
                        onLoad={onMapLoad}
                        onNativeClick={onMapClickNative}
                        dragPan={dragPan}
                        doubleClickZoom={doubleClickZoom}
                        scrollZoom={scrollZoom}
                        transformRequest={transformRequest}
                        onTransitionEnd={onTransitionEnd}
                        interactiveLayerIds={interactiveLayerIds}
                        attributionControl={false}
                        getCursor={getCursor}
                        ref={mapRef}
                        preserveDrawingBuffer={true}
                      />
                    </div>
                    <div className={scss.mapButtons}>
                      <button type='button' className={scss.mapButton}>
                        <FontAwesomeIcon
                          icon={['fal', 'arrows-alt']}
                          size='1x'
                        />
                      </button>
                      <button
                        type='button'
                        onClick={handleSaveToImg}
                        className={scss.mapButton}
                      >
                        <FontAwesomeIcon icon={['fal', 'download']} size='1x' />
                      </button>
                    </div>
                  </Rnd>
                )
              }

              return (
                <Rnd
                  key={object.id}
                  resizeHandleClasses={{
                    topLeft: rndTopLeftCornerHandle,
                    topRight: rndTopRightCornerHandle,
                    bottomRight: rndBottomRightCornerHandle,
                    bottomLeft: rndBottomLeftCornerHandle,
                    top: rndTopHandle,
                    right: rndRightHandle,
                    bottom: rndBottomHandle,
                    left: rndLeftHandle,
                  }}
                  onClick={() => handleElementWindow(object)}
                  id={object.id}
                  className={scss.rndWrapperHover}
                  onDragStop={handleDragStop}
                  onResizeStop={handleResizeStop}
                  lockAspectRatio={type === 'northArrow'}
                  style={{
                    ...style,
                    overflow: 'visible',
                  }}
                  bounds='parent'
                  minHeight={type === 'date' ? 25 : 50}
                  minWidth={type === 'date' ? 80 : 50}
                  maxWidth={`${layoutSize.layoutWidth}px`}
                  maxHeight={`${layoutSize.layoutHeight}px`}
                  dragGrid={[5, 5]}
                  resizeGrid={[5, 5]}
                  default={{
                    x: object.x,
                    y: object.y,
                    width: object.width,
                    height: object.height,
                  }}
                  size={{
                    width: object.width,
                    height: type === 'img' ? 'auto' : object.height,
                  }}
                  position={{
                    x: object.x,
                    y: object.y,
                  }}
                  cancel={
                    type === 'img'
                      ? '.image'
                      : type === 'text'
                      ? '.text'
                      : type === 'legend'
                      ? '.legend'
                      : '.northArrow'
                  }
                >
                  {type === 'img' && (
                    <>
                      <ImageElement
                        ref={imgRef}
                        object={object}
                        imageElements={activeElementWindow}
                      />
                      <ElementButtons
                        object={object}
                        toggleEditWindow={toggleEditWindow}
                        handleDeleteElement={handleDeleteElement}
                      />
                    </>
                  )}
                  {type === 'text' && (
                    <>
                      <TextElement
                        object={object}
                        handleElementClick={() =>
                          setTextAreaElements(!textAreaElements)
                        }
                        handleChangeText={handleChangeText}
                        handleTextBlur={handleTextBlur}
                        textAreaElements={activeElementWindow}
                      />
                      {textAreaElements && (
                        <ElementButtons
                          object={object}
                          toggleEditWindow={toggleEditWindow}
                          handleDeleteElement={handleDeleteElement}
                          copyText={copyText}
                        />
                      )}
                    </>
                  )}
                  {type === 'date' && (
                    <>
                      <DateElement
                        object={object}
                        handleElementClick={() =>
                          setDateStampElements(!dateStampElements)
                        }
                        handleChangeText={handleChangeText}
                        handleTextBlur={handleTextBlur}
                        dateStampElements={activeElementWindow}
                      />
                      {dateStampElements && (
                        <ElementButtons
                          object={object}
                          toggleEditWindow={toggleEditWindow}
                          handleDeleteElement={handleDeleteElement}
                        />
                      )}
                    </>
                  )}
                  {type === 'legend' && (
                    <>
                      <LegendElement
                        object={object}
                        handleElementClick={() =>
                          setLegendElementVisible(!legendElementVisible)
                        }
                        excludedLayers={excludedLayers}
                        legendElement={activeElementWindow}
                      />
                      <ElementButtons
                        object={object}
                        toggleEditWindow={toggleEditWindow}
                        handleDeleteElement={handleDeleteElement}
                        handleRefreshItemLegend={handleRefreshItemLegend}
                      />
                    </>
                  )}
                  {type === 'northArrow' && (
                    <>
                      <NorthArrowElement
                        object={object}
                        viewport={viewport}
                        handleElementClick={() =>
                          setNorthArrowElements(!northArrowElements)
                        }
                        northArrowElements={activeElementWindow}
                      />
                      {northArrowElements && (
                        <ElementButtons
                          object={object}
                          toggleEditWindow={toggleEditWindow}
                          handleDeleteElement={handleDeleteElement}
                        />
                      )}
                    </>
                  )}
                  {type === 'scaleBar' && (
                    <>
                      <Scale
                        object={object}
                        viewport={viewport}
                        mapRef={mapRef.current?.getMap()}
                        handleElementClick={() =>
                          setScaleBarElement(!scaleBarElement)
                        }
                        scaleBarElement={activeElementWindow}
                        handleChangeScale={handleChangeScale}
                      />
                      {scaleBarElement && (
                        <ElementButtons
                          object={object}
                          toggleEditWindow={toggleEditWindow}
                          handleDeleteElement={handleDeleteElement}
                        />
                      )}
                    </>
                  )}
                </Rnd>
              )
            })}
        </div>
        <div className={scss.alignControls}>
          <div className={scss.horizontalAlignButtons}>
            <button
              type='button'
              onClick={() => handleAlign('left')}
              title='Align Left'
            >
              <FontAwesomeIcon icon='align-left' />
            </button>
            <button
              type='button'
              onClick={() => handleAlign('center')}
              title='Align Center'
            >
              <FontAwesomeIcon icon='align-center' />
            </button>
            <button
              type='button'
              onClick={() => handleAlign('right')}
              title='Align Right'
            >
              <FontAwesomeIcon icon='align-right' />
            </button>
          </div>
          <div className={scss.verticalAlignButtons}>
            <button
              type='button'
              onClick={() => handleVerticalAlign('top')}
              title='Align Top'
            >
              <FontAwesomeIcon icon='arrow-up' />
            </button>
            <button
              type='button'
              onClick={() => handleVerticalAlign('middle')}
              title='Align Middle'
            >
              <FontAwesomeIcon icon='grip-lines' />
            </button>
            <button
              type='button'
              onClick={() => handleVerticalAlign('bottom')}
              title='Align Bottom'
            >
              <FontAwesomeIcon icon='arrow-down' />
            </button>
          </div>
        </div>
        <div className={scss.zoomControls}>
          <button
            type='button'
            onClick={handleZoomIn}
            disabled={zoomLevel >= maxZoom}
            className={zoomLevel >= maxZoom ? 'disabled' : ''}
            title='Zoom in'
          >
            <FontAwesomeIcon icon='plus' />
          </button>
          <button
            type='button'
            onClick={handleZoomOut}
            disabled={zoomLevel <= minZoom}
            className={zoomLevel <= minZoom ? 'disabled' : ''}
            title='Zoom out'
          >
            <FontAwesomeIcon icon='minus' />
          </button>
          <button
            type='button'
            onClick={handleResetZoom}
            disabled={zoomLevel === 100}
            className={zoomLevel === 100 ? 'disabled' : ''}
            title='Reset zoom'
          >
            <FontAwesomeIcon icon='expand' />
          </button>
        </div>
        {activeElement && (
          <ElementStyle
            style={style}
            object={elements[getActiveObject()]}
            changeStyle={changeStyle}
            editToolPosition={editToolPosition}
            setEditToolPosition={updateEditToolPosition}
            elements={elements}
            handleUpdateElements={handleUpdateElements}
            onClose={() => {
              setActiveElement(null)
              setActiveElementWindow(null)
            }}
          />
        )}
      </div>
    )
  } else {
    ui = getPDF()
  }

  return (
    <div onClick={pageClicked} className={scss.layoutCenter}>
      <div className={scss.printControlsWrapper}>
        {mode === 'PDF' && (
          <div className={scss.backLayoutButton}>
            <button type='button' onClick={toggleMode}>
              <FontAwesomeIcon
                icon='chevron-left'
                size='xs'
                pull='left'
                color='white'
                className={scss.printArrowIcon}
              />
              <span>BACK TO LAYOUT</span>
            </button>
          </div>
        )}
        {mode !== 'PDF' && (
          <div className={scss.printControlsGroup}>
            <div className={scss.btnOptions}>
              <button
                type='button'
                className={scss.backButton}
                onClick={backToMap}
              >
                <FontAwesomeIcon
                  icon='chevron-left'
                  size='xs'
                  pull='left'
                  color='white'
                  className={scss.printArrowIcon}
                />
                <span>Back</span>
              </button>
              <div className={scss.mapOptions}>
                <button
                  type='button'
                  className={scss.textBtn}
                  onClick={() => handleAddText(10)}
                >
                  <img
                    src={textIcon}
                    className={scss.printTextIcon}
                    alt='Add text'
                  />
                </button>
                <button
                  type='button'
                  className={scss.textBtn}
                  onClick={triggerInput}
                >
                  <input
                    style={hiddenInputStyle}
                    ref={inputRef}
                    type='file'
                    accept='image/*'
                    onChange={handleImageChange}
                  />
                  <img
                    src={imageIcon}
                    className={scss.printIcon}
                    alt='Select file'
                  />
                </button>

                <div
                  className={[
                    scss.insertBtn,
                    scss.printDropdown,
                    dropdownScss.dropdown,
                  ].join(' ')}
                  onClick={toggleInsertDropdown}
                >
                  <img
                    src={plusIcon}
                    className={scss.printPlusIcon}
                    alt='Add items'
                  />
                  <FontAwesomeIcon
                    icon='chevron-down'
                    size='xs'
                    pull='right'
                    color='white'
                    className={scss.printArrowIcon}
                  />

                  {showInsertDropdown && (
                    <div
                      className={[
                        scss.printDropdownContent,
                        dropdownScss.dropdownContent,
                      ].join(' ')}
                    >
                      <div
                        className={scss.printDropdownItem}
                        onClick={handleAddDateStamp}
                      >
                        <img
                          src={dateIcon}
                          className={scss.printIcon}
                          alt='Date stamp'
                        />
                        Date Stamp
                      </div>
                      <div
                        className={scss.printDropdownItem}
                        onClick={handleAddNorthArrow}
                      >
                        <img
                          src={northIcon}
                          className={scss.printNorthIcon}
                          alt='North arrow'
                        />
                        North Arrow
                      </div>
                      <div
                        className={scss.printDropdownItem}
                        onClick={handleAddLegend}
                      >
                        <img
                          src={legendIcon}
                          className={scss.printIcon}
                          alt='Legend'
                        />
                        Legend
                      </div>
                      <div
                        className={scss.printDropdownItem}
                        onClick={handleAddScaleBar}
                      >
                        Scale Bar
                      </div>
                    </div>
                  )}
                </div>

                {printSettingsVisible && (
                  <PrintSettings
                    showPrintSettings={showPrintSettings}
                    toggleOrient={toggleOrient}
                    orientPortrait={orientPortrait}
                    viewport={viewport}
                    onViewportChange={onViewportChange}
                    dpi={dpi}
                    setDPI={setDpi}
                    pageSize={pageSize}
                    setPageSize={handlePageSizeChange}
                  />
                )}
                <div
                  className={[
                    scss.settingsBtn,
                    scss.printDropdown,
                    dropdownScss.dropdown,
                  ].join(' ')}
                  onClick={() => showPrintSettings(true)}
                >
                  Settings
                  <FontAwesomeIcon
                    icon='cog'
                    size='xs'
                    pull='right'
                    color='white'
                    className={scss.printArrowIcon}
                  />
                </div>

                <div className={scss.historyButtonGroup}>
                  <button
                    type='button'
                    className={[
                      scss.btn,
                      scss.historyBtn,
                      history.past.length === 0 ? scss.disabled : '',
                    ].join(' ')}
                    onClick={handleUndo}
                    disabled={history.past.length === 0}
                    title='Undo'
                  >
                    <FontAwesomeIcon icon='undo' size='sm' color='white' />
                  </button>
                  <button
                    type='button'
                    className={[
                      scss.btn,
                      scss.historyBtn,
                      history.future.length === 0 ? scss.disabled : '',
                    ].join(' ')}
                    onClick={handleRedo}
                    disabled={history.future.length === 0}
                    title='Redo'
                  >
                    <FontAwesomeIcon icon='redo' size='sm' color='white' />
                  </button>
                </div>

                <button
                  type='button'
                  className={[scss.btn, scss.lockBtn].join(' ')}
                  onClick={toggleMapLock}
                >
                  <div>
                    <span>
                      {lockMap ? 'Unlock Map' : 'Lock Map'}
                      <img
                        src={lockMap ? lockIcon : unlockIcon}
                        alt={lockMap ? 'Unlock map' : 'Lock map'}
                        className={scss.lockIcon}
                      />
                    </span>
                  </div>
                </button>
              </div>

              <div className={scss.pdfOptions}>
                <PrintLayouts
                  toggleFileDropdown={toggleFileDropdown}
                  showFileDropdown={showFileDropdown}
                  toggleMode={toggleMode}
                  setInitialMap={setInitialMap}
                  setPrintState={state => {
                    setMode(state.mode)
                    setOrientPortrait(state.orientPortrait)
                    setElements(state.elements)
                    setDpi(state.dpi)
                    setEditMode(state.editMode)
                    setActiveElement(state.activeElement)
                    setActiveElementWindow(state.activeElementWindow)
                    setTextAreaElements(state.textAreaElements)
                    setDateStampElements(state.dateStampElements)
                    setLegendElementVisible(state.legendElement)
                    setNorthArrowElements(state.northArrowElements)
                    setScaleBarElement(state.scaleBarElement)
                    setLayoutName(state.layoutName)
                    setLayoutNameError(state.layoutNameError)
                    setSelectedLayout(state.selectedLayout)
                    setSavedLayouts(state.savedLayouts)
                    setLayoutOption(state.layoutOption)
                    setEditToolPosition(state.editToolPosition)
                    setLockMap(state.lockMap)
                    setExcludedLayers(state.excludedLayers)
                    setPageSize(state.pageSize)
                  }}
                  dataToSave={{
                    mode,
                    orientPortrait,
                    elements,
                    dpi,
                    editMode,
                    activeElement,
                    activeElementWindow,
                    textAreaElements,
                    dateStampElements,
                    legendElement: legendElementVisible,
                    northArrowElements,
                    scaleBarElement,
                    layoutName,
                    layoutNameError,
                    selectedLayout,
                    savedLayouts,
                    layoutOption,
                    editToolPosition,
                    lockMap,
                    excludedLayers,
                    pageSize,
                    width: containerWidth,
                    height: containerHeight,
                  }}
                />
                <button
                  type='button'
                  className={[scss.btn, scss.exportBtn].join(' ')}
                  onClick={() => setShowExportOptions(true)}
                >
                  Export
                  <img
                    src={exportIcon}
                    alt='Export map'
                    className={scss.exportIcon}
                  />
                </button>
                <button
                  type='button'
                  className={[scss.btn, scss.printBtn].join(' ')}
                  onClick={toggleMode}
                >
                  Print
                  <img
                    src={printIcon}
                    alt='Print map'
                    className={scss.printIcon}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {ui}
      {showExportOptions && (
        <ExportOptions
          onExport={handleExport}
          onClose={() => setShowExportOptions(false)}
          visibleLayers={getVisibleLayers()}
        />
      )}
    </div>
  )
}

export default PrintFC
