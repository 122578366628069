import React, { useState, useEffect, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import appScss from '../../../../App/App.scss'
import dropdownScss from '../../../../CSSModules/Dropdown.scss'

import portraitIcon from '../../../../../assets/icons/print/OrientationPortrait.svg'
import landscapeIcon from '../../../../../assets/icons/print/OrientationLandscape.svg'
import ReactSelect from '../../../../../components/ReactSelect/ReactSelect'
import * as util from '../../../../../utilities/util'
import { PAGE_SIZE_LOOKUP } from '../../PrintConstants'

import settingsScss from './PrintSettings.scss'
import scss from '../../Print.scss'
import modalScss from '../../../../CSSModules/Modal.scss'
import mainModalTheme from '../../../../../utilities/componentConstants/mainModalTheme'

function SimpleDialog({
  toggleOrient,
  orientPortrait,
  showPrintSettings,
  dpi,
  setDPI,
  setPageSize,
  pageSize,
  onViewportChange,
  viewport,
}) {
  const [open, setOpen] = useState(true)
  const [urlObjects, setUrlObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)

  // Create page size options grouped by standard
  const pageSizeOptions = useMemo(
    () => [
      {
        label: 'Common US Sizes',
        options: [
          { value: 'LETTER', label: PAGE_SIZE_LOOKUP.LETTER.label },
          { value: 'LEGAL', label: PAGE_SIZE_LOOKUP.LEGAL.label },
          { value: 'TABLOID', label: PAGE_SIZE_LOOKUP.TABLOID.label },
        ],
      },
      {
        label: 'ISO A Series',
        options: [
          { value: 'A4', label: PAGE_SIZE_LOOKUP.A4.label },
          { value: 'A3', label: PAGE_SIZE_LOOKUP.A3.label },
          { value: 'A2', label: PAGE_SIZE_LOOKUP.A2.label },
          { value: 'A1', label: PAGE_SIZE_LOOKUP.A1.label },
          { value: 'A0', label: PAGE_SIZE_LOOKUP.A0.label },
        ],
      },
      {
        label: 'ANSI Series',
        options: [
          { value: 'ANSI_A', label: PAGE_SIZE_LOOKUP.ANSI_A.label },
          { value: 'ANSI_B', label: PAGE_SIZE_LOOKUP.ANSI_B.label },
          { value: 'ANSI_C', label: PAGE_SIZE_LOOKUP.ANSI_C.label },
          { value: 'ANSI_D', label: PAGE_SIZE_LOOKUP.ANSI_D.label },
          { value: 'ANSI_E', label: PAGE_SIZE_LOOKUP.ANSI_E.label },
        ],
      },
      {
        label: 'Architectural Series',
        options: [
          { value: 'ARCH_A', label: PAGE_SIZE_LOOKUP.ARCH_A.label },
          { value: 'ARCH_B', label: PAGE_SIZE_LOOKUP.ARCH_B.label },
          { value: 'ARCH_C', label: PAGE_SIZE_LOOKUP.ARCH_C.label },
          { value: 'ARCH_D', label: PAGE_SIZE_LOOKUP.ARCH_D.label },
          { value: 'ARCH_E', label: PAGE_SIZE_LOOKUP.ARCH_E.label },
        ],
      },
    ],
    []
  )

  const [dpiOptions] = useState([
    { label: '300ppi - Print', value: 300 },
    { label: '240ppi - Print', value: 240 },
    { label: '72ppi - Web', value: 72 },
  ])

  const [initialDPI, setInitialDPI] = useState(
    util.objectKeyVal(dpi, dpiOptions, 'value')
  )

  // Find the current page size option
  const currentPageSize = useMemo(() => {
    for (const group of pageSizeOptions) {
      const option = group.options.find(opt => opt.value === pageSize)
      if (option) return option
    }
    return pageSizeOptions[0].options[0] // Default to Letter if not found
  }, [pageSize, pageSizeOptions])

  const handleCancel = () => {
    setOpen(false)
    showPrintSettings(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    showPrintSettings(false)
  }

  const handleDPIChange = newDPI => {
    Object.defineProperty(window, 'devicePixelRatio', {
      get: function () {
        return newDPI / 96
      },
    })
    onViewportChange({ ...viewport, zoom: viewport.zoom + 0.001 })
    setDPI(newDPI)
  }

  useEffect(() => {
    setUrlObjects(null)
  }, [urlObjects])

  useEffect(() => {
    setMountMakeDataConfig(false)
  }, [mountMakeDataConfig])

  return (
    <ThemeProvider theme={mainModalTheme}>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='print-settings-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='print-settings-dialog'>
          Settings
          <FontAwesomeIcon icon='times' onClick={handleOnClose} />
        </DialogTitle>

        <DialogContent>
          <div className={settingsScss['print-settings-grid']}>
            {/* Page Size and DPI */}
            <div className={settingsScss['print-settings-grid-left']}>
              <label className={modalScss['modal-primary-label']}>
                Output Size
                <ReactSelect
                  options={pageSizeOptions}
                  onChange={setPageSize}
                  value={currentPageSize}
                  isFixed
                />
              </label>
              <label
                className={modalScss['modal-primary-label']}
                style={{ marginTop: '16px' }}
              >
                Resolution
                <ReactSelect
                  options={dpiOptions}
                  onChange={handleDPIChange}
                  defaultValue={initialDPI}
                  isFixed
                />
              </label>
            </div>
            {/* Orientation */}
            <div className={settingsScss['print-settings-grid-right']}>
              <label className={modalScss['modal-primary-label']}>
                Orientation
                <ButtonGroup
                  className={scss['mui-double-toggle-button']}
                  variant='contained'
                  color='primary'
                  aria-label='text primary button group'
                >
                  <Button
                    onClick={orientPortrait === true ? toggleOrient : null}
                  >
                    <img
                      src={landscapeIcon}
                      alt='Landscape'
                      className={scss.landscapeIcon}
                    />
                  </Button>
                  <Button
                    onClick={orientPortrait === false ? toggleOrient : null}
                  >
                    <img
                      src={portraitIcon}
                      alt='Portrait'
                      className={scss.portraitIcon}
                    />
                  </Button>
                </ButtonGroup>
              </label>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className={modalScss.modalAltButton}
            onClick={handleCancel}
            type='button'
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default SimpleDialog
