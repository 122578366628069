import { StyleSheet } from '@react-pdf/renderer'

// Available Pages Sizes are at
// https://github.com/diegomura/react-pdf/blob/master/src/utils/pageSizes.js
export const PAGE_SIZE_LOOKUP = {
  LETTER: {
    width: 612,
    height: 792,
    label: 'Letter (8.5" × 11")',
  },
  LEGAL: {
    width: 612,
    height: 1008,
    label: 'Legal (8.5" × 14")',
  },
  TABLOID: {
    width: 792,
    height: 1224,
    label: 'Tabloid (11" × 17")',
  },
  A0: {
    width: 2384,
    height: 3370,
    label: 'A0 (841mm × 1189mm)',
  },
  A1: {
    width: 1684,
    height: 2384,
    label: 'A1 (594mm × 841mm)',
  },
  A2: {
    width: 1191,
    height: 1684,
    label: 'A2 (420mm × 594mm)',
  },
  A3: {
    width: 842,
    height: 1191,
    label: 'A3 (297mm × 420mm)',
  },
  A4: {
    width: 595,
    height: 842,
    label: 'A4 (210mm × 297mm)',
  },
  ANSI_A: {
    width: 612,
    height: 792,
    label: 'ANSI A (8.5" × 11")',
  },
  ANSI_B: {
    width: 792,
    height: 1224,
    label: 'ANSI B (11" × 17")',
  },
  ANSI_C: {
    width: 1224,
    height: 1584,
    label: 'ANSI C (17" × 22")',
  },
  ANSI_D: {
    width: 1584,
    height: 2448,
    label: 'ANSI D (22" × 34")',
  },
  ANSI_E: {
    width: 2448,
    height: 3168,
    label: 'ANSI E (34" × 44")',
  },
  ARCH_A: {
    width: 648,
    height: 864,
    label: 'Arch A (9" × 12")',
  },
  ARCH_B: {
    width: 864,
    height: 1296,
    label: 'Arch B (12" × 18")',
  },
  ARCH_C: {
    width: 1296,
    height: 1728,
    label: 'Arch C (18" × 24")',
  },
  ARCH_D: {
    width: 1728,
    height: 2592,
    label: 'Arch D (24" × 36")',
  },
  ARCH_E: {
    width: 2592,
    height: 3456,
    label: 'Arch E (36" × 48")',
  },
}

export const DEFAULT_SELECT_OPTION = {
  label: 'Default Layout',
  value: 'default',
}

export const MAP_ELEMENT = {
  type: 'map',
  x: 5,
  y: 5,
  width: 782,
  height: 540,
  borderWidth: 2,
  borderColor: '#000000',
  borderTransparent: false,
}

export const TEXT_ELEMENT = {
  type: 'text',
  text: 'Enter Text...',
  x: 5,
  y: 555,
  width: 150,
  height: 50,
  borderWidth: 1,
  borderColor: '#000000',
  borderTransparent: false,
  textColor: '#000000',
  fontSize: 12,
  fontFamily: 'Montserrat',
  bgColor: '#ffffff',
  bgTransparent: false,
  opacity: 100,
  fontWeight: 'normal',
  fontStyle: 'normal',
  textDecoration: 'none',
  textAlign: 'left',
  padding: 5,
}

export const NORTH_ARROW = {
  type: 'northArrow',
  x: 730,
  y: 550,
  width: 50,
  height: 50,
  borderWidth: 0,
  borderColor: '#000000',
  borderTransparent: false,
}

export const SCALE_BAR = {
  type: 'scaleBar',
  text: '',
  x: 350,
  y: 550,
  width: 110,
  height: 20,
  borderWidth: 0,
  borderColor: '#000000',
  borderTransparent: false,
  textColor: '#000000',
  fontSize: 12,
  fontFamily: 'Montserrat',
  bgColor: '#ffffff',
  bgTransparent: false,
  opacity: 100,
  fontWeight: 'normal',
  fontStyle: 'normal',
  textDecoration: 'none',
  textAlign: 'left',
  padding: 5,
}

export const LEGEND_ELEMENT = {
  type: 'legend',
  x: 10,
  y: 10,
  borderWidth: 1,
  borderColor: '#000000',
  borderTransparent: false,
  textColor: '#000000',
  bgColor: '#ffffff',
  bgTransparent: false,
  opacity: 100,
  padding: 0,
}

export const PDF_STYLE_SHEET = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Gilroy-Light',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Gilroy-Light',
  },
  image: {
    marginVertical: 1,
    marginHorizontal: 1,
    width: '300pt',
    height: '300pt',
    position: 'absolute',
  },
  imageWrapper: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundSize: 'cover',
    overflow: 'hidden',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  map: {
    position: 'absolute',
  },
})

export const EXPORT_FORMATS = {
  PDF: {
    id: 'pdf',
    label: 'PDF Document',
    extension: '.pdf',
    mimeType: 'application/pdf',
  },
  PNG: {
    id: 'png',
    label: 'PNG Image',
    extension: '.png',
    mimeType: 'image/png',
  },
  JPEG: {
    id: 'jpeg',
    label: 'JPEG Image',
    extension: '.jpg',
    mimeType: 'image/jpeg',
  },
}

export const EXPORT_QUALITY_PRESETS = {
  LOW: {
    id: 'low',
    label: 'Low (72 DPI)',
    dpi: 72,
    compression: 0.6,
  },
  MEDIUM: {
    id: 'medium',
    label: 'Medium (150 DPI)',
    dpi: 150,
    compression: 0.8,
  },
  HIGH: {
    id: 'high',
    label: 'High (300 DPI)',
    dpi: 300,
    compression: 0.9,
  },
  ULTRA: {
    id: 'ultra',
    label: 'Ultra (600 DPI)',
    dpi: 600,
    compression: 1.0,
  },
}
