import React, { memo, useMemo } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  Image,
  StyleSheet,
} from '@react-pdf/renderer'

import { PDF_STYLE_SHEET, PAGE_SIZE_LOOKUP } from './PrintConstants.js'
import northArrowImage from './LayoutElements/NorthArrowElement/NorthArrowIcons/simple.png'
import ViewerLegend from './ViewerElements/ViewerLegend'

// Helper function to convert opacity percentage to decimal
const normalizeOpacity = opacity => {
  if (typeof opacity !== 'number' || Number.isNaN(opacity)) return 1
  return Math.max(0, Math.min(1, opacity / 100))
}

const MapElement = memo(({ object, mapImage }) => (
  <Image
    style={[
      PDF_STYLE_SHEET.map,
      {
        top: object.y,
        left: object.x,
        width: Math.max(object.width || 0, 0),
        height: Math.max(object.height || 0, 0),
        border:
          !object.borderTransparent &&
          `${object.borderWidth}pt solid ${object.borderColor}`,
        opacity: normalizeOpacity(object.opacity),
      },
    ]}
    src={mapImage}
  />
))

const NorthArrowElement = memo(({ object, mapboxobj }) => (
  <Image
    style={{
      ...PDF_STYLE_SHEET.image,
      top: object.y,
      left: object.x,
      width: Math.max(object.width || 0, 0),
      height: Math.max(object.height || 0, 0),
      transform: `rotate(${-mapboxobj.transform.bearing}deg)`,
      border:
        !object.borderTransparent &&
        `${object.borderWidth}pt solid ${object.borderColor}`,
      opacity: normalizeOpacity(object.opacity),
    }}
    src={northArrowImage}
  />
))

const ImageElement = memo(({ object }) => (
  <Image
    style={[
      PDF_STYLE_SHEET.image,
      {
        top: object.y,
        left: object.x,
        width: Math.max(object.width - object.borderWidth * 2 || 0, 0),
        height: Math.max(object.height - object.borderWidth * 2 || 0, 0),
        border:
          !object.borderTransparent &&
          `${object.borderWidth}pt solid ${object.borderColor}`,
        opacity: normalizeOpacity(object.opacity),
      },
    ]}
    src={object.imagePreviewUrl}
  />
))

const TextElement = memo(({ object, textAlign }) => (
  <View
    style={[
      {
        display: 'flex',
        position: 'absolute',
        top: object.y,
        left: object.x,
        width: Math.max(object.width || 0, 0),
        height: Math.max(object.height || 0, 0),
        border:
          !object.borderTransparent &&
          `${object.borderWidth}pt solid ${object.borderColor}`,
        backgroundColor: !object.bgTransparent && object.bgColor,
        opacity: normalizeOpacity(object.opacity),
        justifyContent: object.verticalAligment,
        textAlign,
      },
    ]}
  >
    <Text
      style={[
        {
          fontFamily: object.fontFamily,
          position: 'absolute',
          fontWeight: object.fontWeight,
          fontStyle: object.fontStyle,
          textDecoration: object.textDecoration,
          color: object.textColor,
          fontSize: object.fontSize * 0.75,
        },
      ]}
    >
      {object.text}
    </Text>
  </View>
))

const DateElement = memo(({ object }) => (
  <Text
    style={[
      PDF_STYLE_SHEET.text,
      {
        top: object.y,
        left: object.x,
        fontWeight: object.fontWeight,
        fontStyle: object.fontStyle,
        textDecoration: object.textDecoration,
        textAlign: object.textAlign,
        color: object.textColor,
        backgroundColor: !object.bgTransparent && object.bgColor,
        fontSize: object.fontSize * 0.75,
        padding: object.padding,
        width: Math.max(object.width || 0, 0),
        height: Math.max(object.height || 0, 0),
        margin: 0,
        border:
          !object.borderTransparent &&
          `${object.borderWidth}pt solid ${object.borderColor}`,
        opacity: normalizeOpacity(object.opacity),
      },
    ]}
  >
    {object.text}
  </Text>
))

const ScaleBarElement = memo(({ object }) => (
  <View
    style={[
      {
        position: 'absolute',
        top: object.y,
        left: object.x,
        height: 50,
        width: Math.max(object.width || 0, 0),
        opacity: normalizeOpacity(object.opacity),
      },
    ]}
  >
    <View>
      <Text
        style={[
          {
            fontFamily: object.fontFamily,
            position: 'absolute',
            fontWeight: object.fontWeight,
            fontStyle: object.fontStyle,
            textDecoration: object.textDecoration,
            color: object.textColor,
            fontSize: object.fontSize * 0.75,
          },
        ]}
      >
        {object.distance}
      </Text>
    </View>
    <View>
      <View
        style={{
          position: 'absolute',
          top: 12,
          backgroundColor: 'white',
          height: 10,
          width: Math.max(object.maxWidth * object.ratio || 0, 0),
          border: '1pt solid black',
        }}
      />
    </View>
  </View>
))

const Viewer = memo(
  ({
    mapStyle,
    dataConfig,
    viewport,
    orientPortrait,
    pageWidth,
    pageSize,
    elements,
    mapImage,
    mapboxobj,
    excludedLayers,
  }) => {
    const pdfViewStyle = useMemo(
      () => ({
        display: 'flex',
        justifyContent: 'center',
      }),
      []
    )

    const viewerHeight = window.innerHeight - 125

    const renderElement = useMemo(
      () => object => {
        let textAlign = 'left'
        if (object.horizontalAligment === 'center') textAlign = 'center'
        if (object.horizontalAligment === 'flex-end') textAlign = 'right'

        switch (object.type) {
          case 'map':
            return (
              <MapElement key={object.id} object={object} mapImage={mapImage} />
            )
          case 'northArrow':
            return (
              <NorthArrowElement
                key={object.id}
                object={object}
                mapboxobj={mapboxobj}
              />
            )
          case 'img':
            return <ImageElement key={object.id} object={object} />
          case 'text':
            return (
              <TextElement
                key={object.id}
                object={object}
                textAlign={textAlign}
              />
            )
          case 'date':
            return <DateElement key={object.id} object={object} />
          case 'scaleBar':
            return <ScaleBarElement key={object.id} object={object} />
          case 'legend':
            return (
              <ViewerLegend
                key={object.id}
                mapStyle={mapStyle}
                dataConfig={dataConfig}
                viewport={viewport}
                excludedLayers={excludedLayers}
                object={object}
                style={[
                  PDF_STYLE_SHEET.text,
                  {
                    fontFamily: object.fontFamily,
                    color: object.textColor,
                    border:
                      !object.borderTransparent &&
                      `${object.borderWidth}pt solid ${object.borderColor}`,
                    backgroundColor: !object.bgTransparent && object.bgColor,
                    padding: object.padding,
                    width: Math.max(object.width || 0, 0),
                    height: Math.max(object.height || 0, 0),
                    opacity: normalizeOpacity(object.opacity),
                  },
                ]}
              />
            )
          default:
            return null
        }
      },
      [mapStyle, dataConfig, viewport, mapImage, mapboxobj, excludedLayers]
    )

    return (
      <div style={pdfViewStyle}>
        <PDFViewer width={pageWidth} height={viewerHeight}>
          <Document>
            <Page
              style={PDF_STYLE_SHEET.body}
              orientation={orientPortrait ? 'portrait' : 'landscape'}
              size={
                pageSize.startsWith('ANSI_') || pageSize.startsWith('ARCH_')
                  ? [
                      PAGE_SIZE_LOOKUP[pageSize].width,
                      PAGE_SIZE_LOOKUP[pageSize].height,
                    ]
                  : pageSize.toUpperCase()
              }
            >
              {elements.map(renderElement)}
            </Page>
          </Document>
        </PDFViewer>
      </div>
    )
  }
)

export default Viewer
