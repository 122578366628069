import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { getVisibleLayersFromDataConfig } from '../../../../utilities/dataConfig'
import ViewerLineSwatch from './ViewerLineSwatch'
import ViewerFillSwatch from './ViewerFillSwatch'
import ViewerCircleSwatch from './ViewerCircleSwatch'

// Helper function to normalize opacity
const normalizeOpacity = opacity => {
  if (typeof opacity !== 'number' || Number.isNaN(opacity)) return 1
  return Math.max(0, Math.min(1, opacity / 100))
}

const ViewerLegend = ({
  mapStyle,
  dataConfig,
  viewport,
  excludedLayers,
  object,
}) => {
  const getLegendLayers = () => {
    const style = mapStyle.toJS()
    const tocLayers = dataConfig['tocLayers']
    const legendLayers = getVisibleLayersFromDataConfig(tocLayers, style)
    const uniqueLegendLayers = Array.from(
      new Set(legendLayers.map(JSON.stringify)),
      JSON.parse
    )
    const legendUi = []
    // Reverse layer order to reflect layer order seen in layout
    uniqueLegendLayers.reverse()
    uniqueLegendLayers.forEach((legendLayer, layerIndex) => {
      if (legendLayer.layersArray[0].layer.type === 'raster') return
      if (excludedLayers.includes(legendLayer.layersArray[0].layer.id)) return
      const key = legendLayer.toc.id
      let title = legendLayer.toc.label
      let zoomLabel = null
      // if simple style we want to align the title with the swatch by using display:flex
      let paintPorps = legendLayer.layersArray[0].layer.paint
      let outlinePaintPorps
      let flexAlignTitle = true
      let expressionFound
      let hideLayerHeader
      if (legendLayer.layersArray[0].outline)
        outlinePaintPorps = legendLayer.layersArray[0].outline.paint
      for (let [key, value] of Object.entries(paintPorps)) {
        if (Array.isArray(value)) {
          const flat = value.flat().join(',')
          if (flat.indexOf('case,==,get') !== -1) {
            // data expression
            flexAlignTitle = false
            expressionFound = true
            title = legendLayer.toc.label
          } else if (flat.indexOf('interpolate,linear,zoom') !== -1) {
            // zoom expression
            flexAlignTitle = false
            title = null
            zoomLabel = legendLayer.toc.label
          }
        }
      }
      if (outlinePaintPorps) {
        for (let [key, value] of Object.entries(outlinePaintPorps)) {
          if (Array.isArray(value)) {
            const flat = value.flat().join(',')
            if (flat.indexOf('case,==,get') !== -1) {
              // data expression
              flexAlignTitle = false
              expressionFound = true
              title = legendLayer.toc.label
            } else if (flat.indexOf('interpolate,linear,zoom') !== -1) {
              // zoom expression
              flexAlignTitle = false
              title = null
              zoomLabel = legendLayer.toc.label
            }
          }
        }
      }
      // alsway show title on layerPackages
      if (legendLayer.toc.layerPackage) flexAlignTitle = false

      if (legendLayer.layersArray.length > 1) {
        flexAlignTitle = false
        hideLayerHeader = true
        // this title is for layers holding layers, like TX Wetlands
        title = legendLayer.toc.label
      }

      const items = legendLayer.layersArray
        .filter(layerObj => !excludedLayers.includes(layerObj.layer.id))
        .filter(layerObj => !layerObj.layer.id.includes('_outline'))
        .map((layerObj, itemIndex) => {
          // get layer from mapStyle to build legend swatch
          const layer = style.layers.filter(
            layer => layer.id === layerObj.layer.id
          )
          const type = layer[0].type
          let outline = []
          if (type === 'fill') {
            outline = style.layers.filter(
              layer => layer.id === layerObj.layer.id + '_outline'
            )
          }

          return (
            <View key={`${key}_item_${itemIndex}`}>
              {type === 'line' && (
                <ViewerLineSwatch
                  layer={layer[0]}
                  singleItemTitles={legendLayer.toc.layerPackage}
                  viewport={viewport}
                  zoomLabel={zoomLabel}
                />
              )}
              {type === 'fill' && (
                <ViewerFillSwatch
                  layer={layer[0]}
                  outline={outline[0]}
                  singleItemTitles={legendLayer.toc.layerPackage}
                  viewport={viewport}
                />
              )}
              {type === 'circle' && (
                <ViewerCircleSwatch
                  layer={layer[0]}
                  singleItemTitles={legendLayer.toc.layerPackage}
                  viewport={viewport}
                  zoomLabel={zoomLabel}
                />
              )}
            </View>
          )
        })

      const legendStyle = {
        display: flexAlignTitle ? 'flex' : 'block',
        alignItems: flexAlignTitle ? 'center' : 'none',
        justifyContent: flexAlignTitle ? 'space-between' : 'none',
        margin: 0,
        padding: 0,
      }
      const wrapperStyle = {
        display: 'flex',
        marginHorizontal: 0,
        marginRight: 50,
        marginVertical: 7.5,
        padding: 0,
      }
      const titleWrapperStyle = {
        display: 'flex',
        fontSize: 9,
        fontWeight: 'bold',
        marginTop: 6,
        marginBottom: 3,
      }
      const flexAlignStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 9,
        margin: 0,
        padding: 0,
      }
      const flexColumnStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 9,
        margin: 0,
        padding: 0,
      }

      if (flexAlignTitle) {
        legendUi.push(
          <View key={`${key}_flex_${layerIndex}`}>
            <View>
              <View style={expressionFound ? flexColumnStyle : flexAlignStyle}>
                <Text style={wrapperStyle}>{title}</Text>
                <View>{items}</View>
              </View>
            </View>
          </View>
        )
      } else {
        legendUi.push(
          <View key={`${key}_block_${layerIndex}`} style={legendStyle}>
            <Text style={titleWrapperStyle}>{title}</Text>
            {items}
          </View>
        )
      }
    })
    return legendUi
  }

  const legend = getLegendLayers()
  const legendStyle = {
    fontFamily: 'Montserrat',
    fontSize: 9,
    fontWeight: 'normal',
    color: object.textColor,
    position: 'absolute',
    top: object.y,
    left: object.x,
    backgroundColor: !object.bgTransparent && object.bgColor,
    border:
      !object.borderTransparent &&
      `${object.borderWidth}pt solid ${object.borderColor}`,
    padding: !object.padding ? '6pt 8pt' : `${object.padding}pt`,
    opacity: normalizeOpacity(object.opacity),
    display: 'flex',
  }

  return (
    <View style={legendStyle} className={'legend'}>
      <View key='legend_wrapper'>
        <View>
          <View>{legend}</View>
        </View>
      </View>
    </View>
  )
}

export default ViewerLegend
